import { render, staticRenderFns } from "./InvestigationsDetail.vue?vue&type=template&id=3bc77855&scoped=true"
import script from "./InvestigationsDetail.vue?vue&type=script&lang=ts"
export * from "./InvestigationsDetail.vue?vue&type=script&lang=ts"
import style0 from "./InvestigationsDetail.vue?vue&type=style&index=0&id=3bc77855&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc77855",
  null
  
)

export default component.exports