import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=d0526bce&scoped=true"
import script from "./UserDetail.vue?vue&type=script&lang=ts"
export * from "./UserDetail.vue?vue&type=script&lang=ts"
import style0 from "./UserDetail.vue?vue&type=style&index=0&id=d0526bce&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0526bce",
  null
  
)

export default component.exports